<template>
  <div class="columns-top columns is-multiline">
    <div class="column is-half-tablet is-one-third-desktop">
      <div class="message-usps message p-4 is-shadowless is-primary">
        <h3 class="title is-3 has-text-centered has-text-blue-dark">What can you do with Your S2M Passport?</h3>
        <table class="table table-usps has-background-transparent">
          <tbody>
            <tr>
              <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
              <td><span class="is-size-5 has-text-weight-bold">Get AI driven opportunities</span></td>
            </tr>
            <tr>
              <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
              <td><span class="is-size-5 has-text-weight-bold">Ask the expert</span></td>
            </tr>
            <tr>
              <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
              <td><span class="is-size-5 has-text-weight-bold">Stay in touch</span></td>
            </tr>
            <tr>
              <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
              <td><span class="is-size-5 has-text-weight-bold">Manage your bookings and data</span></td>
            </tr>
            <tr>
              <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
              <td><span class="is-size-5 has-text-weight-bold">Access the entire S2M Ecosystem</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column">
      <create-profile class="registration-form-wrapper"></create-profile>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  created() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.message-usps {
  height: 100%;
  .table-usps {
    td {
      padding: 5px 0;
      border: none;
      &:first-child {
        padding-right: $gap;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .registration-form-wrapper {
    margin: 0 auto;
    width: 70%;
  }
}

@media screen and (max-width: $tablet) {
  .columns-top {
    display: flex;
    flex-flow: column;

    .column:nth-child(1) {
      order: 2;
    }
  }
}
</style>
